import React from 'react';
import "./assets/styles/global.css";
import Routers from './routers';
function App() {
  return (
    <Routers/>
  );
}

export default App;
