import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Logo from '../../assets/images/logo-dw-branca.png';
import { IoCaretDownOutline, IoCloseOutline, IoMenuOutline } from "react-icons/io5";
import '../../assets/styles/global.css';
import './menu-styled.css';

export default function Menu() {

    const [dropDown, setDropDown] = useState(false);
    const [modalDropDown, setModalDropDown] = useState(false);
    const [modalCliente, setModalCLiente] = useState(false);
    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);
    const handleClickCliente = () => setModalCLiente(!modalCliente);
    const handleDropDown = () => setDropDown(!dropDown);
    const handleModalDropDown = () => setModalDropDown(!modalDropDown);

    const history = useHistory();

    let scrollTop = function () {
        window.scrollTo(0, 0);
    }

    let goToHome = () => {
        scrollTop();
        history.push("/");
    }

    let handleDropFunction = () => {
        setModalDropDown(false)
        setClick(true);
    }

    // Arrumar DropDown do mobile

    return (
        <header>
            <nav>
                <div className="content-menu">
                    <div className="options">
                        <Link id="styled-option" to="/" onClick={scrollTop}>HOME</Link>
                        <Link id="styled-option" to="/sobre-nos" onClick={scrollTop}>SOBRE NÓS</Link>
                        <div className="servicos-dropdown">
                            <div className="icon-option">
                                <span onClick={handleDropDown}>SERVIÇOS</span>
                                <IoCaretDownOutline onClick={handleDropDown} id="pointer-icon" color="#fff" size="1rem" />
                            </div>
                            <div className={dropDown === false ? "dropdown-options disabled" : "dropdown-options"}>
                                <Link to="/blindagem" onClick={scrollTop}>BLINDAGEM</Link>
                                <Link to="/servicos" onClick={scrollTop}>OUTROS SERVIÇOS</Link>
                            </div>
                        </div>
                        <span id="styled-option" onClick={handleClickCliente}>ÁREA CLIENTE</span>
                    </div>
                    <div className="logo-area">
                        <img src={Logo} alt="logo_dwblindagens" onClick={goToHome} />
                    </div>
                </div>
                <div className="mobile-menu" onClick={handleClick}>
                    <div className="column-mobile">
                        <div className="icon-menu">
                            {click ? (
                                <IoCloseOutline color="#fff" size="3rem" />
                            ) : (
                                <IoMenuOutline color="#fff" size="3rem" />
                            )}
                        </div>
                        <div className="logo-area-mobile">
                            <img src={Logo} alt="logo_dwblindagens" onClick={goToHome} />
                        </div>
                    </div>
                    <div className={!click ? "options-mobile-disable" : "options-mobile"}>
                        <Link to="/" onClick={scrollTop}>HOME</Link>
                        <Link to="/sobre-nos" onClick={scrollTop}>SOBRE NÓS</Link>
                        <span id="option-white" onClick={handleModalDropDown}>SERVIÇOS</span>
                        <span id="option-white" onClick={handleClickCliente}>ÁREA CLIENTE</span>
                    </div>
                </div>
            </nav>
            <div className={!modalDropDown ? "modal-dropdown disabled" : "modal-dropdown"}>
                <IoCloseOutline id="position-icon-menu" color="#fff" size="2rem" onClick={handleDropFunction}/>
                <div className="options-mobile-dropdown">
                    <Link to="/blindagem" onClick={scrollTop}>BLINDAGEM</Link>
                    <Link to="/servicos" onClick={scrollTop}>OUTROS SERVIÇOS</Link>
                </div>
            </div>
            <div className={modalCliente ? "modal-area-cliente" : "modal-area-cliente disabled"}>
                <div className="content-modal-cliente">
                    <h2>Área Do Cliente</h2>
                    <div className="input-cliente-modal">
                        <label htmlFor="input_modal_cliente">Chassi:</label>
                        <input type="text" id="input_modal_cliente" name="modal_chassi" placeholder="Insira o chassi"/>
                    </div>
                    <div className="buttons-modal-cliente">
                        <span onClick={handleClickCliente}>Cancelar</span>
                        <span>Entrar</span>
                    </div>
                </div>
            </div>
        </header>
    );
}