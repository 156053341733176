import React from "react";
import Menu from "../../components/menu";
import Footer from "../../components/footer";
import './blindagem.styled.css';
import '../../assets/styles/global.css';
import SeloGarantia from '../../assets/images/selo-garantia.jpg';
import LogoUdura from '../../assets/images/icon-udura.png';
import CarUdura from '../../assets/images/udura-car.jpg';
import BlindagemImage from '../../assets/images/image-carro-blindado.png';

export default function Blindagem() {
    return (
        <div className="blindagem-content">
            <header>
                <Menu />
            </header>
            <main>
                <section>
                    <div className="background-blindagem">
                        <div className="black-transparent-blindagem">
                            <h2>Blindagem</h2>
                            <p>A blindagem nível III-A com a garantia da maior segurança e durabilidade.</p>
                        </div>
                    </div>
                </section>
                <section className="content-blindagem-page">
                    <div className="about-blindagem">
                        <div className="texts-blindagem">

                            <h4>Blindagem nível III-A</h4>
                            <span>
                                Trabalhamos com dois sistemas de blindagem de eficiência comprovada,
                                parte opaca: UDura e aço e manta. Saiba mais sobre eles com os conteúdos nessa página:
                            </span>
                            <span>
                                Com a blindagem UDURA você ganha valorização na revenda pela garantia estendida e menor desgaste dos itens
                                de segurança como freios e suspensão.
                            </span>
                        </div>
                        <div className="image-udura-garantia">
                            <img src={SeloGarantia} alt="selo_garantia" />
                        </div>
                    </div>
                    <div className="benefits-udura">
                        <div className="width-background">
                            <div className="image-udura-logo">
                                <img src={LogoUdura} alt="logo_udura" />
                            </div>
                            <div className="beneficios-udura">
                                <p>Benefícios e diferenciais:</p>
                                <ul>
                                    <li>10 anos de garantia;</li>
                                    <li>Homologado pelo Exército;</li>
                                    <li>Maior número de camadas de proteção;</li>
                                    <li>Encaixe perfeito à estrutura do veículo;</li>
                                    <li>80% mais leve que aço e 20% mais leve que manta;</li>
                                    <li>Proteção anti-UV;</li>
                                    <li>Eliminação de ruídos;</li>
                                    <li>Alteração mínima do centro de gravidade;</li>
                                    <li>Menor tempo no processo de blindagem;</li>
                                    <li>Melhora no consumo de combustível.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="car-udura">
                        <img src={CarUdura} alt="carro_udura" />
                        <p>Inovador sistema de desenvolvimento de peças com braço tridimensional (3D) à laser a partir dos
                            veículos originais garante que as partes modeladas tenham seus encaixes perfeitos aos contornos e interiores dos veículos,
                            com projeto específico de cada modelo de carro.
                        </p>
                    </div>
                    <div className="blindagem-convencional">
                        <div className="about-covencional">
                            <h4>BLINDAGEM NÍVEL IIIA - ARAMIDA E AÇO</h4>

                            <h5>MANTA</h5>
                            <span>
                                A tecnologia reveste o habitáculo do veículo em mantas de aramida e painéis emborrachados com neoprene em ambos
                                os lados. Por seu poder de elasticidade, peso, aderência e flexibilidade, facilita a adaptação nas estruturas, sem interferir
                                no espaço interior e aparência original do veículo. Aplicados em módulos inteiros recortados de acordo com a área a ser
                                fixada, evitando emendas, que se tornariam pontos vulneráveis.
                            </span>

                            <h5>AÇO</h5>
                            <span id="padding-bottom-span">
                                Aço balístico inoxidável é utilizado em toda estrutura do veículo, como: colunas A e B, barras das portas, fechaduras e
                                em outros locais onde não há fixação total da manta de aramida, eliminando pontos vulneráveis e reforçando toda
                                estrutura original do veículo.
                            </span>
                        </div>
                    </div>
                    <div className="car-udura">
                        <img src={BlindagemImage} alt="carro_udura" />
                    </div>
                    <div className="vidros-glass">
                        <div className="black-transparent-blindagem">
                            <h2>Vidros</h2>
                            <span>
                                Trabalhamos com vidros que oferecem transparência, coloração e estética inigualáveis, com tecnologia consagrada pelo mercado de blindagem,
                                e com garantia de 3, 5 e 10 anos. Sendo que, as que possuem garantia de 10 anos não delaminam.
                            </span>
                            <span id="padding-span-blind">
                                Orientamos a melhor opções para seu veículo !!!!!
                            </span>
                        </div>
                    </div>
                </section>
            </main>
            <footer id="padding-footer-blind">
                <Footer />
            </footer>
        </div>
    );
}