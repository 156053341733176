import React, { useState } from "react";
import Menu from "../../components/menu";
import Footer from "../../components/footer";
import './sobre-styled.css';
import '../../assets/styles/global.css';
import CREX from '../../assets/images/excr.jpeg';
import { IoBulbSharp, IoChatbubbleEllipsesSharp, IoClose, IoHeartSharp, IoThumbsUpSharp } from "react-icons/io5";

export default function SobreNos() {

    const [imageCertificate, setImageCertificate] = useState();
    const [modalSobre, setModalSobre] = useState(false);

    const handleOpenModal = (e: any) => { setModalSobre(true); setImageCertificate(e); };
    const handleCloseModal = () => setModalSobre(false);

    return (
        <div className="sobre-nos">
            <header>
                <Menu />
            </header>
            <main>
                <section>
                    <div className="background-initial-sobre">
                        <div className="content-sobre">
                            <div className="separation-sobre">
                                <div className="title-sobre">
                                    <h2>Nossa História:</h2>
                                </div>
                                <div className="text-maps-sobre-letter">
                                    <span>Há mais de 20 anos eu Willian fundador da DW, trabalhei
                                        na Califórnia e em São Paulo,  sempre voltado à carros, seja
                                        na parte mecânica, restaurações de carros antigo e blindagem.
                                    </span>
                                    <span>
                                        Assumi a responsabilidade de trabalhar com as  novas
                                        tecnologias  de blindagem mantendo a qualidade dos produtos
                                        e serviços com muita tranquilidade, responsabilidade e
                                        segurança.
                                    </span>
                                    <span>
                                        Temos uma equipe  de colaboradores  com domínio nos
                                        equipamento e materiais de blindagem, seriedade e
                                        profissionalismo por todos, prezamos pela Excelência com o
                                        mais alto padrão de acabamento, mantendo a  originalidade do
                                        veículo.
                                    </span>
                                    <h4>
                                        Consultoria para a escolha do veículo e nível de blindagem:
                                    </h4>
                                    <span>Orientamos sobre as melhores opções de veículo e tipo de
                                        blindagem, através de uma análise do seu perfil e necessidades.
                                    </span>
                                </div>
                            </div>
                            <div className="separation-sobre">
                                <div className="title-sobre-black">
                                    <h2 id="font-white">Onde Estamos:</h2>
                                </div>
                                <div className="text-maps-sobre">
                                    <iframe id="maps-sobre" title="google maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3668.8476186592025!2d-47.240997285030076!3d-23.139244484894125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cf4cf6805e666d%3A0x433f6dcce19fd226!2sR.%20Frederico%20Magnusson%2C%20207%20-%20Comercial%20Vitoria%20Martini%2C%20Indaiatuba%20-%20SP%2C%2013347-624!5e0!3m2!1spt-BR!2sbr!4v1630347498576!5m2!1spt-BR!2sbr" loading="lazy"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="valores">
                    <h2>Alguns dos nossos valores</h2>
                    <div className="content-valores">
                        <div className="division-valores">
                            <IoChatbubbleEllipsesSharp id="icon-shadow" color="#323232" size="2.5rem" />
                            <span>Comunicação clara e objetiva</span>
                        </div>
                        <div className="division-valores">
                            <IoHeartSharp id="icon-shadow" color="#323232" size="2.5rem" />
                            <span>Paixão em tudo que fazemos</span>
                        </div>
                        <div className="division-valores">
                            <IoThumbsUpSharp id="icon-shadow" color="#323232" size="2.5rem" />
                            <span>Qualidade em todos os serviços</span>
                        </div>
                        <div className="division-valores">
                            <IoBulbSharp id="icon-shadow" color="#323232" size="2.5rem" />
                            <span>Criatividade e inovação sempre</span>
                        </div>
                    </div>
                </section>
                <section className="anos-sobre">
                    <div className="black-transparent-sobre">
                        <p>Há mais de 20 anos no ramo de blindagem</p>
                    </div>
                </section>
                <section className="certificados">
                    <h1>Certificados</h1>
                    <div className="all-certificate">
                        <div className="certificate">
                            <img src={CREX} alt="IMAGE_TESTE_CR" onClick={() => handleOpenModal(CREX)} />
                            <p>CR DW Blnindagens</p>
                        </div>
                    </div>
                </section>
            </main>
            <footer>
                <Footer />
            </footer>
            <div className={!modalSobre ? "modal-sobre disabled" : "modal-sobre "}>
                <div className="content-modal-sobre">
                    <IoClose id="close-modal" color="#fff" size="1.5rem" onClick={handleCloseModal} />
                    <img src={imageCertificate} alt="certificado" />
                </div>
            </div>
        </div>
    );
}