import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./footer-styled.css";
import "../menu/menu-styled.css";

export default function Footer() {

    const [modalClienteF, setModalCLienteF] = useState(false);
    const handleClickClienteM = () => setModalCLienteF(!modalClienteF);

    let scrollTop = function () {
        window.scrollTo(0, 0);
    }

    return (
        <div className="footer">
            <div className="content-footer">
                <div className="informations-footer">
                    <div className="division-footer">
                        <h4>Blindadora:</h4>
                        <div className="cllnm-footer">
                            <span>Rua Frederico Magnusson, 207</span>
                            <span>CEP - 13347-624</span>
                            <span>Indaiatuba - SP</span>
                        </div>
                    </div>
                    <div className="division-footer">
                        <h4>Telefones:</h4>
                        <div className="cllnm-footer">
                            <span>(19) 3936-5819</span>
                            <span>(11) 98965-5820</span>
                        </div>
                    </div>
                    <div className="division-footer">
                        <h4>Emails:</h4>
                        <div className="cllnm-footer">
                            <span>will@dwblindagens.com.br</span>
                            <span>atendimento@dwblindagens.com.br</span>
                            <span>financeiro@dwblindagens.com.br</span>
                        </div>
                    </div>
                    <div className="division-footer">
                        <h4>Outras Páginas:</h4>
                        <div className="cllnm-footer">
                            <Link to="/" onClick={scrollTop}>Home</Link>
                            <Link to="/sobre-nos" onClick={scrollTop}>Sobre Nós</Link>
                            <Link to="/blindagem" onClick={scrollTop}>Serviços</Link>
                            <span onClick={handleClickClienteM}>Área do Cliente</span>
                        </div>
                    </div>
                </div>
                <div className="span-credits">
                    <span>Todos os direitos reservados por DW Blindagens 2021©. Desenvolvido por Vinicius Takedi</span>
                </div>
            </div>
            <div className={modalClienteF === true ? "modal-area-cliente" : "modal-area-cliente disabled"}>
                <div className="content-modal-cliente">
                    <h2>Área Do Cliente</h2>
                    <div className="input-cliente-modal">
                        <label htmlFor="input_modal_cliente">Chassi:</label>
                        <input type="text" id="input_modal_cliente" name="modal_chassi" placeholder="Insira o chassi"/>
                    </div>
                    <div className="buttons-modal-cliente">
                        <span onClick={handleClickClienteM}>Cancelar</span>
                        <span>Entrar</span>
                    </div>
                </div>
            </div>
        </div>
    );
}